import request from '@/utils/request'

// 查询客户短信模版
export function findAgentsSmsTemplates(params) {
  return request({
    url: `/agents_sms_templates`,
    method: 'get',
    params: params
  })
}

// 客户短信模版选项数据
export function findAgentsSmsTemplatesOptions(params) {
  return request({
    url: `/agents_sms_templates/options`,
    method: 'get',
    params: params
  })
}

// 导入客户通用短信模版
export function importAgentsSmsTemplates(data) {
  return request({
    url: `/agents_sms_templates/import`,
    method: 'post',
    data
  })
}

// 查询单个客户短信模版表单值
export function findAgentsSmsTemplateForm(id) {
  return request({
    url: `/agents_sms_templates/${id}/form`,
    method: 'get'
  })
}

// 更新客户短信模版
export function updateAgentsSmsTemplate(id, data) {
  return request({
    url: `/agents_sms_templates/${id}`,
    method: 'put',
    data
  })
}

// 校验短信模版价格
export function checkAgentsSmsTemplatePrices(id, data) {
  return request({
    url: `/agents_sms_templates/${id}/check_prices`,
    method: 'post',
    data
  })
}

// 批量更新客户短信模版有效性
export function batchUpdateAgentsSmsTemplatesEffective(ids, effective) {
  const data = { ids: ids, effective: effective }
  return request({
    url: `/agents_sms_templates/effective/batch`,
    method: 'patch',
    data
  })
}

// 查询单个短信模版
export function findAgentsSmsTemplate(id) {
  return request({
    url: `/agents_sms_templates/${id}`,
    method: 'get'
  })
}
