<template>
  <div>
    <search-sms-template @submit="submitSearch" />

    <div class="simiot-table-operation-buttons">
      <a-space>
        <a-button
          type="primary"
          @click="showImportModal"
          v-if="isShowMenu('agents_sms_templates/import')"
        >导入短信模版
        </a-button>

        <update-sms-template-effective
          v-if="isShowMenu('agents_sms_templates/effective')"
          :selected-row-keys="selectedRowKeys"
          @completed="fetchData"
        />
      </a-space>
    </div>

    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :scroll="{ x: 'max-content' }"
      :pagination="false"
      @change="sortChange"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      row-key="id"
    >
      <div slot="name" slot-scope="name, record">
        <div v-if="isShowMenu('agents_sms_templates/show')">
          <a @click="showInfoModal(record.id)">{{ name }}</a>
        </div>

        <div v-else> {{ name }} </div>
      </div>

      <div slot="retail_price" slot-scope="retail_price">
        <span>{{ formatFloatDisplay(retail_price) }}</span>
      </div>

      <span slot="actions" slot-scope="text, record">
        <a-dropdown>
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            操作 <a-icon type="down" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item v-if="isHasEditPermission">
              <a @click="showEditModal(record.id)">查看</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 导入模态框 -->
    <import-agents-sms-template
      v-if="isShowImportModal"
      :agent-id="agentId"
      :visible.sync="isShowImportModal"
      @completed="fetchData"
    />

    <!-- 编辑模态框 -->
    <edit-agents-sms-template
      v-if="isShowEditModal"
      :visible.sync="isShowEditModal"
      :id="editingId"
      @completed="fetchData"
    />

    <!-- 详情模态框 -->
    <show-agents-sms-template
      v-if="isShowInfoModal"
      :visible.sync="isShowInfoModal"
      :id="showingId"
    />
  </div>
</template>

<script>
import { hasPermission } from '@/utils/permission'
import { findAgentsSmsTemplates } from '@/api/agents_sms_template'
import SearchSmsTemplate from '@/views/agents_sms_templates/Search'
import Pagination from '@/components/Pagination'
import { formatFloat } from '@/utils/filter'

export default {
  name: 'AgentsSmsTemplateList',
  components: {
    Pagination,
    SearchSmsTemplate,
    ImportAgentsSmsTemplate: () => import('@/views/agents_sms_templates/import/index'),
    EditAgentsSmsTemplate: () => import('@/views/agents_sms_templates/Edit'),
    ShowAgentsSmsTemplate: () => import('@/views/agents_sms_templates/Show'),
    UpdateSmsTemplateEffective: () => import('@/views/agents_sms_templates/UpdateEffective')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowImportModal: false, // 是否显示导入表单弹窗
      isShowEditModal: false, // 是否显示编辑表单弹窗
      isShowInfoModal: false, // 是否显示详情弹窗
      editingId: 0, // 正在编辑的记录id
      selectedRowKeys: [],
      pagination: {
        total_count: 0
      },
      query: { effective: '1' },
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '模版ID',
          fixed: 'left',
          dataIndex: 'base_id'
        },
        {
          title: '模版名称',
          fixed: 'left',
          dataIndex: 'name'
        },
        {
          title: '模版内容',
          dataIndex: 'content'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '有效性',
          dataIndex: 'effective'
        },
        {
          title: '导入时间',
          dataIndex: 'created_at',
          sorter: true,
          sortOrder: this.sort.sort_field === 'created_at' ? this.sort.sort_order : false
        },
        {
          title: '操作',
          dataIndex: 'actions',
          scopedSlots: { customRender: 'actions' },
          fixed: 'right'
        }
      ]
    },

    agentId() {
      return parseInt(this.$route.params.id)
    },

    isHasEditPermission() {
      return hasPermission('agents_sms_templates/edit')
    }
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    formatFloatDisplay(value) {
      if (value === -1) {
        return '-'
      }
      return formatFloat(value)
    },

    isShowMenu(menu) {
      return hasPermission(menu)
    },

    showImportModal() {
      this.isShowImportModal = true
    },

    showEditModal(recordId) {
      this.editingId = recordId
      this.isShowEditModal = true
    },

    showInfoModal(recordId) {
      this.showingId = recordId
      this.isShowInfoModal = true
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      this.selectedRowKeys = []
      findAgentsSmsTemplates(Object.assign({ agent_id: this.agentId }, this.query, this.sort)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }

        this.loading = false
      })
    }
  }
}
</script>
